import React, { useState, useEffect, Fragment } from "react";
import { PageProps } from "gatsby";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { ChevronDown } from "react-feather";
import clsx from "clsx";
import { combineURLs, isProductionHostname } from "../utils/urls";
import { AVAILABLE_PRODUCT, APPLICATION_PRODUCTS } from "../utils/static";

import ProductMenuDropDown from "./ProductMenuDropDown";

import ACPLogo from "../images/general/ACPLogo.webp";

import Menu from "../icons/Menu.svg";
import XClose from "../icons/XClose.svg";

const ACP_CONSOLE_URL = process.env.GATSBY_ACP_CONSOLE_URL;

const Navbar = ({ location }: PageProps) => {
  const { language, originalPath } = useI18next();
  const isProductionState = isProductionHostname();

  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showMenuNavbar, setShowMenuNavbar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 875) {
        setShowMenuNavbar(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setShowProductMenu(false);
    setShowMenuNavbar(false);
  }, [location]);

  const menuClass =
    "text-gray-500 font-semibold lg:mx-3 xl:mx-4 mx-2 text-center flex items-center hover:text-gray-700";

  return (
    <div
      className="relative flex flex-col h-[80px]"
      tabIndex={0}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowMenuNavbar(false);
        }
      }}
    >
      <div className="border-b border-gray-100 h-[80px] bg-white fixed w-full top-0 z-[1000] !font-inter">
        <div className="max-w-[1240px] mx-auto justify-between items-center h-full px-4 xl:px-12 hidden tablet:flex text-sm lg:text-base whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex items-center">
              <Link
                to="/"
                className="flex items-center flex-shrink-0 mr-2 font-semibold lg:mr-10"
              >
                <img
                  alt="logo"
                  src={ACPLogo}
                  className="w-[126px] h-[45px] object-contain"
                />
              </Link>
            </div>
            <div className="items-center hidden tablet:flex">
              <Link
                to={`/`}
                className={menuClass}
                activeClassName="text-primary-600"
              >
                Home
              </Link>
              <button
                id="product-menu"
                type="button"
                aria-label="product menu"
                className={clsx(
                  menuClass,
                  showProductMenu && "!text-gray-700",
                  originalPath.includes("/product") && "!text-gray-700"
                )}
                onClick={() => {
                  setShowProductMenu(!showProductMenu);
                }}
              >
                <span className="mr-2">Product</span>
                <ChevronDown size={20} />
              </button>
              <Link
                to={`/pricing`}
                className={menuClass}
                activeClassName="text-primary-600"
              >
                Pricing
              </Link>
              <a
                href="https://visai-ai.github.io/inference-api-documentation"
                target="_blank"
                className={menuClass}
              >
                API Document
              </a>
              <a
                href="https://acp-guidebook.visai.ai"
                rel="noopener noreferrer"
                target="_blank"
                className={menuClass}
              >
                How to use
              </a>
            </div>
          </div>
          <div className="flex items-center gap-4 lg:gap-6">
            <div className="flex gap-2 xl:gap-6">
              <div className="flex items-center justify-between gap-2 lg:gap-3">
                <Link
                  className={clsx(
                    "!font-inter font-semibold py-2.5 px-4 text-primary-700 rounded-lg",
                    language === "th"
                      ? "bg-primary-50 hover:bg-primary-100"
                      : "bg-white hover:bg-primary-50"
                  )}
                  to={originalPath}
                  language={"th"}
                >
                  TH
                </Link>
                <span className="w-px bg-gray-200 h-7"></span>
                <Link
                  className={clsx(
                    "!font-inter font-semibold py-2.5 px-4 text-primary-700 rounded-lg",
                    language === "en"
                      ? "bg-primary-50 hover:bg-primary-100"
                      : "bg-white hover:bg-primary-50"
                  )}
                  to={originalPath}
                  language={"en"}
                >
                  EN
                </Link>
              </div>
              <div className="flex items-center gap-3 lg:gap-4">
                <a
                  className="py-2.5 px-4 rounded-lg text-gray-500 font-semibold text-center flex items-center hover:text-primary-700"
                  href={
                    ACP_CONSOLE_URL
                      ? combineURLs(ACP_CONSOLE_URL, "/auth/sign-in")
                      : "#"
                  }
                  onClick={() => {
                    if (isProductionState) {
                      window.dataLayer.push({
                        event: "sign_in_clicked",
                      });
                    }
                  }}
                >
                  Sign in
                </a>
                <a
                  className="py-2.5 px-4 rounded-lg bg-primary hover:bg-primary-dark text-white font-semibold"
                  href={
                    ACP_CONSOLE_URL
                      ? combineURLs(ACP_CONSOLE_URL, "/auth/register")
                      : "#"
                  }
                  onClick={() => {
                    if (isProductionState) {
                      window.dataLayer.push({
                        event: "register_clicked",
                      });
                    }
                  }}
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between h-full mx-auto tablet:hidden px-6 sm:px-8 md:px-12">
          <div className="flex items-center justify-center w-7">
            {showMenuNavbar ? (
              <button
                type="button"
                aria-label="close menu navbar"
                className="text-gray-500 hover:text-gray-600 w-6 h-6 flex justify-center items-center"
                onClick={() => setShowMenuNavbar(false)}
              >
                <XClose className="w-4 h-4 stroke-current stroke-[2] inherit-stroke-w" />
              </button>
            ) : (
              <button
                type="button"
                aria-label="show menu navbar"
                className="text-gray-700 hover:text-gray-800 w-6 h-6 flex justify-center items-center"
                onClick={() => setShowMenuNavbar(true)}
              >
                <Menu className="w-6 h-6 stroke-current" />
              </button>
            )}
          </div>
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center flex-shrink-0 mr-2 font-semibold lg:mr-10"
            >
              <img
                alt="logo"
                src={ACPLogo}
                className="w-[126px] h-[45px] object-contain"
              />
            </Link>
          </div>
          <div className="w-7"></div>
        </div>
      </div>
      <MenuNavbar
        location={location}
        showMenuNavbar={showMenuNavbar}
        setShowMenuNavbar={setShowMenuNavbar}
      />
      <ProductMenuDropDown
        showProductMenu={showProductMenu}
        setShowProductMenu={setShowProductMenu}
      />
    </div>
  );
};

type MenuNavbarProp = {
  location: any;
  showMenuNavbar: boolean;
  setShowMenuNavbar: React.Dispatch<React.SetStateAction<boolean>>;
};

const MenuNavbar = ({
  location,
  showMenuNavbar,
  setShowMenuNavbar,
}: MenuNavbarProp) => {
  const { language, originalPath } = useI18next();
  const isProductionState = isProductionHostname();

  const [showProductSubMenu, setShowProductSubMenu] = useState(false);

  useEffect(() => {
    if (!showMenuNavbar) {
      setShowProductSubMenu(false);
    }
  }, [showMenuNavbar]);

  useEffect(() => {
    setShowMenuNavbar(false);
  }, [language]);

  const menuClass =
    "text-gray-500 outline-none font-semibold px-4 py-3 text-center w-full flex items-center hover:text-gray-700 hover:bg-gray-100";

  return (
    <div
      id="menuNavbar"
      className={clsx(
        "fixed top-[80px] z-[1000] left-0 md:max-w-[390px] w-full flex flex-col gap-0.5 bg-white md:rounded-br-md shadow-lg max-h-[calc(100dvh_-_80px)] overflow-auto",
        showMenuNavbar
          ? "visible opacity-100 transition-opacity duration-300"
          : "invisible opacity-0 w-0 h-0 overflow-hidden"
      )}
    >
      {/* Language */}
      <div className="flex flex-col gap-3 px-4 py-6 border-b border-gray-200">
        <Link
          className={clsx(
            "font-semibold py-2 px-4 rounded-lg w-min whitespace-nowrap",
            language === "th"
              ? "bg-primary-50 hover:bg-primary-100 text-primary-700"
              : "bg-white hover:bg-primary-50 text-gray-600"
          )}
          to={originalPath}
          language={"th"}
        >
          ภาษาไทย
        </Link>
        <Link
          className={clsx(
            "font-semibold py-2 px-4 rounded-lg w-min  whitespace-nowrap !font-inter",
            language === "en"
              ? "bg-primary-50 hover:bg-primary-100 text-primary-700"
              : "bg-white hover:bg-primary-50 text-gray-600"
          )}
          to={originalPath}
          language={"en"}
        >
          English
        </Link>
      </div>
      {/* Product */}
      <div className="overflow-auto border-b border-gray-200 !font-inter">
        <Link
          to="/"
          className={
            "flex items-center px-4 hover:bg-gray-50 py-4 font-semibold text-center lg:mx-4 text-gray-600 hover:text-primary-600"
          }
          onClick={() => {
            setShowMenuNavbar(false);
          }}
          activeClassName="text-primary-600"
        >
          Home
        </Link>
        <div>
          <button
            type="button"
            aria-label="product menu"
            className={clsx(
              menuClass,
              "justify-between",
              showProductSubMenu && "!text-gray-700",
              originalPath.includes("/product") && "!text-gray-700"
            )}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setShowProductSubMenu(
                (previousShowProductSubMenu) => !previousShowProductSubMenu
              );
            }}
          >
            <span className="mr-2">Product</span>
            <span
              className={clsx(
                "transition-transform",
                showProductSubMenu && "rotate-180"
              )}
            >
              <ChevronDown className="stroke-current" size={20} />
            </span>
          </button>
          <div
            className={clsx(
              "transition-all ease-out duration-100 overflow-hidden flex flex-col gap-3",
              showProductSubMenu ? `h-auto` : "h-0"
            )}
          >
            {/* Application */}
            <>
              <div className="text-gray-700 text-sm font-semibold px-4 mt-2">
                AI Application
              </div>
              <div className="flex flex-col gap-1">
                {APPLICATION_PRODUCTS.map((product: any, index) => {
                  return (
                    <a
                      key={index}
                      href={product.url}
                      aria-label="DocuMate"
                      className="px-4 py-3 flex items-center gap-4 hover:bg-gray-50"
                      target={"_blank"}
                    >
                      <img
                        src={product.image}
                        alt={product.alt}
                        className="w-5 h-5 object-contain shrink-0"
                      />
                      <span className="text-gray-700 font-semibold">
                        {product.name}
                      </span>
                    </a>
                  );
                })}
              </div>
            </>
            <>
              <div className="text-gray-700 text-sm font-semibold px-4 mt-2">
                AI Models
              </div>
              <div className="flex flex-col gap-1">
                {AVAILABLE_PRODUCT.map((product, index) => {
                  return (
                    <Fragment key={index}>
                      {/* @ts-ignore */}
                      <Link
                        to={`/product/${product.pathName}`}
                        onClick={() => {
                          setShowMenuNavbar(false);
                        }}
                        className={
                          "px-4 py-3 flex items-center gap-4 hover:bg-gray-50 text-gray-700 font-semibold"
                        }
                        activeClassName="!text-primary-600"
                      >
                        {showProductSubMenu && (
                          <img
                            alt={product.imageAlt}
                            src={product.image}
                            className={
                              "w-5 h-5 object-contain shrink-0 bg-[#efecf7] rounded"
                            }
                          />
                        )}
                        <span>{product.name}</span>
                      </Link>
                    </Fragment>
                  );
                })}
              </div>
            </>
          </div>
        </div>
        <Link
          to="/pricing"
          className={menuClass}
          onClick={() => {
            setShowMenuNavbar(false);
          }}
          activeClassName="text-primary-600"
        >
          Pricing
        </Link>
        <a
          href="https://visai-ai.github.io/inference-api-documentation"
          target="_blank"
          className={menuClass}
        >
          API Document
        </a>
        <a
          href="https://acp-guidebook.visai.ai"
          rel="noopener noreferrer"
          target="_blank"
          className={menuClass}
        >
          How to Use
        </a>
      </div>
      {/* Authentication */}
      <div className="flex flex-col gap-3 px-4 py-6 !font-inter">
        <a
          className="py-2.5 px-4 rounded-lg font-semibold bg-primary hover:bg-primary-dark text-white text-center"
          href={
            ACP_CONSOLE_URL
              ? combineURLs(ACP_CONSOLE_URL, "/auth/register")
              : "#"
          }
          onClick={() => {
            if (isProductionState) {
              window.dataLayer.push({
                event: "register_clicked",
              });
            }
          }}
        >
          Register
        </a>
        <a
          className="py-2.5 px-4 w-full rounded-lg text-gray-500 font-semibold text-center hover:text-primary border border-gray-200"
          href={
            ACP_CONSOLE_URL
              ? combineURLs(ACP_CONSOLE_URL, "/auth/sign-in")
              : "#"
          }
          onClick={() => {
            if (isProductionState) {
              window.dataLayer.push({
                event: "sign_in_clicked",
              });
            }
          }}
        >
          Sign in
        </a>
      </div>
    </div>
  );
};

export default Navbar;
