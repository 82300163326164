export const isProductionHostname = () => {
  return typeof window !== "undefined" ? window.location.hostname === "acp.visai.ai" : false
}

export const combineURLs = (baseURL: string, relativeURL: string) => {
  return relativeURL
    ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
    : baseURL;
};

export const getInferenceDemoURL = (endpoint: string) => {
  if (typeof window !== "undefined") {
    return `${window.location.protocol}//${endpoint}.${process.env.GATSBY_INFER_DOMAIN}/demo/predict`;
  } else {
    return `https://${endpoint}.${process.env.GATSBY_INFER_DOMAIN}/demo/predict`;
  }
};

export const getInferenceURL = (endpoint: string) => {
  if (typeof window !== "undefined") {
    return `${window.location.protocol}//${endpoint}.${process.env.GATSBY_INFER_DOMAIN}/predict`;
  } else {
    return `https://${endpoint}.${process.env.GATSBY_INFER_DOMAIN}/predict`;
  }
};
