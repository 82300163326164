import SentimentAnalysis from "../images/products/Sentiment-Analysis.webp";
import WordTokenization from "../images/products/Word-Tokenization.webp";
import SpeechToText from "../images/products/Speech-To-Text.webp";
import MachineTranslation from "../images/products/Machine-Translation.webp";
import OCR from "../images/products/OCR.webp";
import OCRCard from "../images/products/OCR-Card.webp";
import OCRPassport from "../images/products/OCR-Passport.webp";
import OCRReceipt from "../images/products/OCR-Receipt.webp"
import OCRLicensePlate from "../images/products/OCR-License-Plate.webp"
import SpeechSegmentation from "../images/products/Speech-Segmentation.webp";
import TSR from "../images/products/TSR.webp";
import TextClassification from "../images/products/Text-Classification.webp";
import DocuMateLogo from "../images/products/Documate-Logo.webp";
import VocalogLogo from "../images/products/Vocalog-Logo.webp"

export const APPLICATION_PRODUCTS = [
  {
    name: "DocuMate",
    image: DocuMateLogo,
    alt: "DocuMate",
    url: "https://documate.visai.ai",
  },
  {
    name: "Vocalog",
    image: VocalogLogo,
    alt: "Vocalog",
    url: "https://vocalog.visai.ai",
  },
];

export const SENTIMENT_PRODUCT = {
  id: "d1767e6e-92ba-4f3c-8d00-8c1ab9e27c07",
  slug: "sentiment-analysis",
  name: "Sentiment Analysis",
  customizedID: "1",
  customizedSlug: "sentiment",
  nameTH: "วิเคราะห์อารมณ์",
  pathName: "sentiment-analysis",
  description:
    "AI can classify emotions from text into 3 categories: positive, neutral, or negative.",
  descriptionTH:
    "AI จำแนกอารมณ์จากข้อความ ในทิศทางเป็นบวก กลาง หรือลบ เพื่อพัฒนาระบบการรับฟังความคิดเห็นของลูกค้า",
  type: "text",
  image: SentimentAnalysis,
  endpoint: "sentiment",
  isAvailable: true,
  imageAlt: "Sentiment Analysis วิเคราะห์อารมณ์",
  pricing: [
    {
      productName: "Sentiment Analysis: Product Review",
      productType: "AI Marketplace",
      creditRequest: "0.04 Credit / 1000 Characters",
      creditPerUnit: 0.04,
      requestUnit: 1000,
      unit: "Characters"
    },
    {
      productType: "Customized AI",
      creditTraining: "10.00 Credit / 60 mins",
      creditRequest: "0.04 Credit / 1000 Characters",
      isFree: true,
      creditPerUnit: 0.04,
      requestUnit: 1000,
      unit: "Characters"
    },
  ],
};

export const WORD_TOKENIZATION = {
  id: "289a5418-e17d-4569-9e6f-3af095602b70",
  slug: "word-tokenization",
  name: "Word Tokenization",
  nameTH: "ตัดคำภาษาไทย",
  pathName: "word-tokenization",
  description:
    "AI can split sentences into words for use in text search, keyword extraction, and data retrieval.",
  descriptionTH:
    "AI ตัด “คำ” จากประโยค ช่วยพัฒนาระบบการค้นหาข้อความ คีย์เวิร์ด หรือการสืบค้นข้อมูล",
  type: "text",
  image: WordTokenization,
  endpoint: "wordtokenization",
  isAvailable: true,
  imageAlt: "Word Tokenization ตัดคำภาษาไทย",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "0.04 Credit / 1000 Characters",
      creditPerUnit: 0.04,
      requestUnit: 1000,
      unit: "Characters"
    },
  ],
};

export const SPEECH_TO_TEXT = {
  id: "c7ba44f3-0de6-4457-8743-75438e3eac60",
  slug: "speech-to-text",
  name: "Speech to Text",
  nameTH: "แปลงเสียงเป็นข้อความ",
  pathName: "speech-to-text",
  description:
    "AI can transcribe speech into text, supporting multiple file types such as mp3, wav, and flac.",
  descriptionTH:
    "AI ถอดเสียงพูดเป็นข้อความ ใช้ถอดไฟล์เสียงได้หลายชนิด ไม่ว่าจะเป็น mp3, wav, flac",
  type: "voice",
  image: SpeechToText,
  endpoint: "stt",
  isAvailable: true,
  imageAlt: "Speech to Text แปลงเสียงเป็นข้อความ",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "0.30 Credit / 15 Seconds",
      creditPerUnit: 0.30,
      requestUnit: 15,
      unit: "Seconds"
    },
  ],
};

export const MACHINE_TRANSLATION = {
  id: "a8250609-0c21-41f8-a343-9915f030b6a8",
  slug: "machine-translation",
  name: "Machine Translation",
  nameTH: "แปลภาษาด้วยเครื่อง",
  pathName: "machine-translation",
  description:
    "AI can translate between Thai-English and English-Thai on documents.",
  descriptionTH:
    "AI แปลภาษาด้วยเครื่อง ไทย-อังกฤษ และอังกฤษ-ไทย เพิ่มความรวดเร็วในการแปล",
  type: "text",
  image: MachineTranslation,
  endpoint: "mt",
  isAvailable: true,
  imageAlt: "Machine Translation แปลภาษาด้วยเครื่อง",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "0.50 Credit / 1000 Characters",
      creditPerUnit: 0.50,
      requestUnit: 1000,
      unit: "Characters"
    },
  ],
};

export const OCR_GENERAL_DOCUMENT = {
  id: "408bd27d-cf2c-4a23-ae45-d62b6462edc2",
  slug: "ocr-general-document",
  name: "OCR General Document",
  nameTH: "แปลงข้อความจากภาพเอกสาร",
  pathName: "ocr-general-document",
  description:
    "AI can read images of documents such as PDF, PNG, and JPG and convert them into text.",
  descriptionTH:
    "AI อ่านภาพเอกสารจากไฟล์ เช่น PDF, PNG, JPG แล้วแปลงเป็นข้อความ",
  type: "image",
  image: OCR,
  endpoint: "ocrdoc",
  isAvailable: true,
  imageAlt: "OCR General Document แปลงข้อความจากภาพเอกสาร",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "1.00 Credit / 1 Page",
      creditPerUnit: 1,
      requestUnit: 1,
      unit: "Page"
    },
  ],
};

export const SPEECH_SEGMENTATION = {
  id: "24d5463d-fb5d-4f75-ae0a-de77e54b77e1",
  slug: "speech-segmentation",
  name: "Speech Segmentation",
  nameTH: "แบ่งส่วนเสียงพูด",
  pathName: "speech-segmentation",
  description:
    "AI can detect human speech from other sounds and is widely used in voice-activated apps.",
  descriptionTH:
    "AI ตรวจจับเสียงพูดของคนออกจากเสียงรบกวนอื่นๆ สำหรับแอปพลิเคชันที่สั่งงานด้วยเสียง",
  type: "voice",
  image: SpeechSegmentation,
  endpoint: "speechsegmentation",
  isAvailable: true,
  imageAlt: "Speech Segmentation แบ่งส่วนเสียงพูด",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "0.20 Credit / 15 Seconds",
      creditPerUnit: 0.20,
      requestUnit: 15,
      unit: "Seconds"
    },
  ],
};

export const TIME_SERIES_FORECASTING = {
  id: "",
  slug: "timeseries",
  customizedID: "2",
  customizedSlug: "timeseries",
  name: "Time Series Forecasting",
  nameTH: "คาดการณ์ข้อมูลในอนาคต",
  pathName: "time-series-forecasting",
  description:
    "AI predicts future values based on historical data, mainly used in finance and retail.",
  descriptionTH:
    "AI คาดการณ์ข้อมูลอนาคตจากข้อมูลในอดีต สำหรับใช้ในอุตสาหกรรม หรือการขายปลีก เช่น คาดการณ์จำนวนยอดขายในอนาคต",
  type: "tabular",
  image: TSR,
  endpoint: "ts",
  isAvailable: true,
  imageAlt: "Time Series Forecasting คาดการณ์ข้อมูลในอนาคต",
  pricing: [
    {
      productType: "Customized AI",
      creditTraining: "15.00 Credit / 60 mins",
      creditRequest: "1.00 Credit / 100 Data points",
      isFree: true,
      creditPerUnit: 1,
      requestUnit: 100,
      unit: "Data points"
    },
  ],
};

export const OCR_THAI_ID_CARD = {
  id: "03bf712a-c153-4aaa-84c0-34fcf5e21fc7",
  slug: "ocr-thai-id-card",
  name: "OCR Thai ID Card",
  nameTH: "แปลงข้อมูลจากบัตรประชาชน",
  pathName: "ocr-thai-id-card",
  description:
    "AI reads ID card images and converts them into Thai-English data.",
  descriptionTH:
    "AI อ่านภาพบัตรประชาชน พร้อมถอดข้อมูลบนหน้าบัตรออกมา เช่น ชื่อ นามสกุล ที่อยู่ วันเกิด",
  type: "image",
  image: OCRCard,
  endpoint: "ocridcard",
  isAvailable: true,
  imageAlt: "OCR ID Card แปลงข้อมูลจากบัตรประชาชน",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "1.00 Credit / 1 Card (Page)",
      creditPerUnit: 1,
      requestUnit: 1,
      unit: "Page"
    },
  ],
};

export const OCR_PASSPORT = {
  id: "a936cf7d-b8f9-4955-9103-a90c030a0c14",
  slug: "ocr-passport",
  name: "OCR Passport",
  nameTH: "แปลงข้อมูลจากภาพหนังสือเดินทาง",
  pathName: "ocr-passport",
  description:
    "AI technology is capable of scanning and analyzing passport images, extracting relevant information, and converting it into structured data.",
  descriptionTH:
    "AI อ่านภาพหนังสือเดินทาง พร้อมถอดข้อมูลบนหน้าบัตรออกมา เช่น ชื่อ นามสกุล ประเทศ วันเกิด",
  type: "image",
  image: OCRPassport,
  endpoint: "ocrpassport",
  isAvailable: true,
  imageAlt: "OCR Passport แปลงข้อมูลจากหนังสือเดินทาง",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "0.75 Credit / 1 Passport Page",
      creditPerUnit: 0.75,
      requestUnit: 1,
      unit: "Page"
    },
  ],
};

export const OCR_RECEIPT = {
  id: "11635d67-80e6-4fa6-a275-3385081c1460",
  slug: "ocr-receipt",
  name: "OCR Receipt",
  nameTH: "แปลงข้อมูลจากภาพหนังสือเดินทาง",
  pathName: "ocr-receipt",
  description:
    "AI reads images of receipts and converts them into text.",
  descriptionTH:
    "AI reads images of receipts and converts them into text.",
  type: "image",
  image: OCRReceipt,
  endpoint: "ocrreceipt",
  isAvailable: true,
  imageAlt: "OCR Receipt แปลงข้อมูลจากใบเสร็จ",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "1.25 Credit / 1 Receipt (Page)",
      creditPerUnit: 1.25,
      requestUnit: 1,
      unit: "Page"
    },
  ],
};

export const OCR_LICENSE_PLATE = {
  id: "3bcdcd6f-0b7a-4b1e-b332-ddedac944815",
  slug: "ocr-license-plate",
  name: "OCR License Plate",
  nameTH: "แปลงข้อมูลจากภาพทะเบียนรถ",
  pathName: "ocr-license-plate",
  description:
    "AI reads images of license plate and converts them into text.",
  descriptionTH:
    "AI reads images of license plate and converts them into text.",
  type: "image",
  image: OCRLicensePlate,
  endpoint: "ocrlicenseplate",
  isAvailable: true,
  imageAlt: "OCR License Plate แปลงข้อมูลจากทะเบียนรถ",
  pricing: [
    {
      productType: "AI Marketplace",
      creditRequest: "1 Credit / 1 License plate (Page)",
      creditPerUnit: 1,
      requestUnit: 1,
      unit: "Page"
    },
  ],
};

export const TEXT_CLASSIFICATION = {
  id: "3ce87af5-9745-432a-af3c-12a525ff52a3",
  slug: "text-classification",
  customizedID: "3",
  customizedSlug: "text-classification",
  name: "Text Classification",
  nameTH: "จำแนกข้อความ",
  pathName: "text-classification",
  description:
    "AI categorizes text content by classifying topics or sort the text based on the group that should correspond to it.",
  descriptionTH:
    "AI จัดประเภทข้อความของเนื้อหา เช่น จำแนกหัวข้อ ประเภทสินค้า หรือผู้รับผิดชอบ",
  type: "text",
  image: TextClassification,
  endpoint: "textclass",
  isAvailable: true,
  imageAlt: "Text Classification จำแนกข้อความ",
  pricing: [
    {
      productName: "Text Classification: Product Review",
      productType: "AI Marketplace",
      creditRequest: "0.25 Credit / 1000 Characters",
      creditPerUnit: 0.25,
      requestUnit: 1000,
      unit: "Characters"
    },
    {
      productType: "Customized AI",
      creditTraining: "10.00 Credit / 30 mins",
      creditRequest: "0.25 Credit / 1000 Characters",
      isFree: true,
      creditPerUnit: 0.25,
      requestUnit: 1000,
      unit: "Characters"
    },
  ],
};

export const PRODUCTS = [
  SENTIMENT_PRODUCT,
  WORD_TOKENIZATION,
  SPEECH_TO_TEXT,
  MACHINE_TRANSLATION,
  SPEECH_SEGMENTATION,
  OCR_GENERAL_DOCUMENT,
  OCR_THAI_ID_CARD,
  OCR_PASSPORT,
  OCR_RECEIPT,
  OCR_LICENSE_PLATE,
  TIME_SERIES_FORECASTING,
  TEXT_CLASSIFICATION,
];

export const AVAILABLE_PRODUCT = PRODUCTS.filter(
  (product) => product.isAvailable === true
);

export const CUSTOMIZED_AI_PRODUCTS = [SENTIMENT_PRODUCT, TEXT_CLASSIFICATION, TIME_SERIES_FORECASTING]
export const AI_MODEL_PRODUCTS = [SENTIMENT_PRODUCT,
  WORD_TOKENIZATION,
  SPEECH_TO_TEXT,
  MACHINE_TRANSLATION,
  SPEECH_SEGMENTATION,
  OCR_GENERAL_DOCUMENT,
  OCR_THAI_ID_CARD,
  OCR_PASSPORT,
  OCR_RECEIPT,
  OCR_LICENSE_PLATE,
  TEXT_CLASSIFICATION,]


export const DEFAULT_DOCUMENT_FILE_CONDITIONS = {
  exceedFileSize: false,
  invalidFileType: false,
  exceedPageLimit: false,
}

export const DEFAULT_IMAGE_FILE_CONDITIONS = {
  exceedFileSize: false,
  invalidFileType: false,
}