import React from "react";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

import Facebook from "../icons/Facebook.svg";
import LinkedIn from "../icons/LinkedIn.svg";
import Twitter from "../icons/Twitter.svg";
import Youtube from "../icons/Youtube.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="py-12 pb-4 md:py-12 bg-primary-800">
        <div className="max-w-[1240px] px-6 sm:px-8 md:px-12 mx-auto flex md:flex-row flex-col justify-between gap-6 md:gap-16">
          <div className="grow">
            <div className="flex flex-wrap items-center gap-2.5 mobile:gap-3 md:gap-5">
              <Link to="/" placeholder="">
                <StaticImage
                  placeholder="none"
                  objectFit="contain"
                  src={"../images/general/ACPLogoWhite.webp"}
                  alt="acp white logo"
                  className="w-[142px] h-[56px]"
                />
              </Link>
              <a href="https://visai.ai" target="_blank">
                <StaticImage
                  placeholder="none"
                  objectFit="contain"
                  src={"../images/general/VISAILogoWhite.webp"}
                  alt="visai white logo"
                  className="w-[147px] h-[32px]"
                />
              </a>
            </div>
            <div className="md:mt-8 mt-6 md:text-base text-sm text-primary-200 max-w-[380px]">
              <div className="mb-1 font-semibold">{t("visai-company")}</div>
              <p>{t("address")}</p>
              <div className="mt-2">
                <a href="tel:020964491" aria-label="visai phone number">
                  {t("tel")}: 0-2096-4491
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap md:gap-[76px] gap-2 shrink-0">
            <div className="flex flex-col gap-3 basis-0 grow">
              <Link
                to={`/pricing`}
                className="text-sm font-semibold whitespace-nowrap text-primary-200 md:text-base w-fit"
                placeholder={"Pricing"}
              >
                Pricing
              </Link>
              <a
                href="https://visai-ai.github.io/inference-api-documentation"
                rel="noopener noreferrer"
                target="_blank"
                className="text-sm font-semibold whitespace-nowrap text-primary-200 md:text-base w-fit"
              >
                API Document
              </a>
              <a
                href="https://acp-guidebook.visai.ai/en/home"
                rel="noopener noreferrer"
                target="_blank"
                className="text-sm font-semibold whitespace-nowrap text-primary-200 md:text-base w-fit"
              >
                How to Use
              </a>
            </div>
            <div className="flex flex-col gap-3 basis-0 grow">
              <Link
                to={`/terms-of-use`}
                className="text-sm font-semibold whitespace-nowrap text-primary-200 md:text-base w-fit"
                placeholder={"Terms of Use"}
              >
                Terms of Use
              </Link>
              <Link
                to={`/privacy-notice`}
                className="text-sm font-semibold whitespace-nowrap text-primary-200 md:text-base w-fit"
                placeholder={"Privacy Notice and Policy"}
              >
                Privacy Notice and Policy
              </Link>
              <Link
                to={`/cookie-policy`}
                className="text-sm font-semibold whitespace-nowrap text-primary-200 md:text-base w-fit"
                placeholder={"Cookies Policy"}
              >
                Cookies Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 pb-10 md:bg-primary-900 bg-primary-800">
        <div className="max-w-[1240px] px-6 sm:px-8 md:px-12 mx-auto flex flex-wrap-reverse gap-6">
          <div className="text-sm text-primary-300 md:text-base grow">
            © 2023 AI Cloud Platform. All rights reserved.
            {process.env.GATSBY_PUBLIC_PROJ_VERSION && (
              <div className="text-primary-300 text-[9px]">
                {process.env.GATSBY_PUBLIC_PROJ_VERSION}
              </div>
            )}
          </div>
          <div className="flex items-center gap-6">
            <a
              aria-label="Twitter"
              href="https://twitter.com/VisaiAI"
              target="_blank"
              className="group"
            >
              <Twitter className="w-6 h-6 transition-all duration-300 fill-primary-300 group-hover:scale-125" />
            </a>
            <a
              aria-label="LinkedIn"
              href="https://www.linkedin.com/company/visai-ai"
              target="_blank"
              className="group"
            >
              <LinkedIn className="w-6 h-6 transition-all duration-300 fill-primary-300 group-hover:scale-125" />
            </a>
            <a
              aria-label="Facebook"
              href="https://facebook.com/VisaiAI"
              target="_blank"
              className="group"
            >
              <Facebook className="w-6 h-6 transition-all duration-300 fill-primary-300 group-hover:scale-125" />
            </a>
            <a
              aria-label="Youtube"
              href="https://youtube.com/@visaiai4873"
              target="_blank"
              className="group"
            >
              <Youtube className="w-6 h-6 transition-all duration-300 fill-primary-300 group-hover:scale-125" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
