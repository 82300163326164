import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { AVAILABLE_PRODUCT } from "../utils/static";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

import { APPLICATION_PRODUCTS } from "../utils/static";

type ProductMenuDropDownProps = {
  showProductMenu: boolean;
  setShowProductMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

type ProductTabsProps = {
  productType: string;
  amount: number;
};

type ProductRadioTabsProps = {
  productTabs: Array<ProductTabsProps>;
  selectedProductType: string;
  setSelectedProductType: React.Dispatch<React.SetStateAction<string>>;
};

type ProductProps = {
  pathName: string;
  image: string;
  alt: string;
  name: string;
};

const ProductRadioTabs = ({
  productTabs,
  selectedProductType,
  setSelectedProductType,
}: ProductRadioTabsProps) => {
  return (
    <div className="flex w-full gap-0 bg-white border-b border-gray-200 md:gap-2 lg:gap-3 xl:gap-4">
      {productTabs.map(({ productType, amount }) => {
        const isSelectedProductTabs = selectedProductType === productType;
        return (
          <div
            key={productType}
            className="flex-auto cursor-pointer basis-0"
            onClick={() => setSelectedProductType(productType)}
          >
            <div
              className={clsx(
                "flex items-center justify-center h-full text-sm font-semibold transition duration-100 gap-2 text-center pb-2 border-b-2",
                isSelectedProductTabs
                  ? "text-primary-700 border-primary-700/100"
                  : "text-gray-500 border-primary-700/0",
                "group hover:text-primary-700 hover:border-primary-700/100"
              )}
            >
              {productType}
              <span
                className={clsx(
                  "px-2 py-0.5 rounded-2xl group-hover:text-primary-700 group-hover:bg-primary-50",
                  isSelectedProductTabs
                    ? "text-primary-700 bg-primary-50"
                    : "text-gray-700 bg-gray-100"
                )}
              >
                {amount}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ProductMenuDropDown = ({
  showProductMenu,
  setShowProductMenu,
}: ProductMenuDropDownProps) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const productMenuElement = useRef<HTMLDivElement>(null);

  const textAIModelProducts = AVAILABLE_PRODUCT.filter(
    (product) => product.type === "text"
  );
  const speechAIModelProducts = AVAILABLE_PRODUCT.filter(
    (product) => product.type === "voice"
  );
  const imageAIModelProducts = AVAILABLE_PRODUCT.filter(
    (product) => product.type === "image"
  );
  const tabularAIModelProducts = AVAILABLE_PRODUCT.filter(
    (product) => product.type === "tabular"
  );

  useEffect(() => {
    if (showProductMenu) {
      productMenuElement?.current?.focus();
    }
  }, [showProductMenu]);

  return (
    <div
      ref={productMenuElement}
      tabIndex={0}
      onBlur={(event) => {
        if (
          !event.currentTarget.contains(event.relatedTarget) &&
          event?.relatedTarget?.id !== "product-menu"
        ) {
          setShowProductMenu(false);
        }
      }}
      className={clsx(
        "transition duration-100 self-center fixed top-[80px] mt-1.5 outline-none flex flex-col gap-6",
        "lg:max-w-[902px] h-0 hidden tablet:block tablet:h-auto max-w-[calc(100vw_-_200px)] w-full bg-white z-[1000] shadow-lg rounded-xl border border-gray-200",
        showProductMenu ? "visible opacity-100" : "invisible opacity-0"
      )}
    >
      {/* Header */}
      <div className="flex flex-col gap-6 max-h-[calc(100dvh_-_100px)] overflow-auto p-8">
        <section className="flex flex-col gap-1">
          <span className="font-semibold text-gray-900 !font-inter">
            Product
          </span>
          <p className="text-sm text-gray-600">
            {t("product-menu.description")}
          </p>
        </section>
        {/* Body */}
        {/* AI Marketplace */}
        <section className="flex flex-col gap-6 !font-inter">
          <div className="text-gray-900 font-semibold pb-3 border-b border-gray-200">
            AI Marketplace
          </div>
          <div className="flex items-start gap-3">
            {/* AI Models */}
            <section className="shrink-0 w-[70%] flex flex-col gap-6 border-r border-gray-200">
              <div className="text-primary-700 font-semibold text-sm">
                AI Models
              </div>
              <div className="flex items-start lg:gap-6 gap-2">
                <div className="basis-0 grow flex flex-col gap-6">
                  {/* Text AI Models */}
                  <div className="flex flex-col gap-3">
                    <div className="text-sm text-gray-700 font-semibold">
                      Text
                    </div>
                    <div className="flex flex-col gap-2">
                      {textAIModelProducts.map(
                        (product: any, index: number) => {
                          return (
                            <ProductMenuCard
                              key={index}
                              pathName={product.pathName}
                              image={product.image}
                              alt={product.alt}
                              name={product.name}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Voice/Speech AI Models */}
                  <div className="flex flex-col gap-3">
                    <div className="text-sm text-gray-700 font-semibold">
                      Voice/Speech
                    </div>
                    <div className="flex flex-col gap-2">
                      {speechAIModelProducts.map(
                        (product: any, index: number) => {
                          return (
                            <ProductMenuCard
                              key={index}
                              pathName={product.pathName}
                              image={product.image}
                              alt={product.alt}
                              name={product.name}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="basis-0 grow flex flex-col gap-6">
                  {/* Tabular AI Models */}
                  <div className="flex flex-col gap-3">
                    <div className="text-sm text-gray-700 font-semibold">
                      Tabular
                    </div>
                    <div className="flex flex-col gap-2">
                      {tabularAIModelProducts.map(
                        (product: any, index: number) => {
                          return (
                            <ProductMenuCard
                              key={index}
                              pathName={product.pathName}
                              image={product.image}
                              alt={product.alt}
                              name={product.name}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Vision/Image AI Models */}
                  <div className="flex flex-col gap-3">
                    <div className="text-sm text-gray-700 font-semibold">
                      Vision/Image
                    </div>
                    <div className="flex flex-col gap-2">
                      {imageAIModelProducts.map(
                        (product: any, index: number) => {
                          return (
                            <ProductMenuCard
                              key={index}
                              pathName={product.pathName}
                              image={product.image}
                              alt={product.alt}
                              name={product.name}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="h-full w-px bg-gray-200"></div>
            {/* AI Applications */}
            <section className="shrink-0 w-[30%] flex flex-col gap-6 pr-6">
              <div className="text-primary-700 font-semibold text-sm">
                AI Applications
              </div>
              {/* Text AI Models */}
              <div className="flex flex-col gap-3">
                {APPLICATION_PRODUCTS.map((product, index) => {
                  return (
                    <a
                      key={index}
                      href={product.url}
                      aria-label={product.name}
                      className="p-3 flex items-center gap-4 rounded-lg hover:bg-gray-50"
                      target={"_blank"}
                    >
                      <img
                        src={product.image}
                        alt={product.alt}
                        className="w-6 h-6 object-contain"
                      />
                      <span className="text-gray-900 font-semibold">
                        {product.name}
                      </span>
                    </a>
                  );
                })}
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

const ProductMenuCard = ({ pathName, image, alt, name }: ProductProps) => {
  return (
    <Link
      to={`/product/${pathName}`}
      aria-label="DocuMate"
      className="p-3 flex items-start gap-4 rounded-lg hover:bg-gray-50"
      target={"_blank"}
    >
      <img
        src={image}
        alt={alt}
        className="w-6 h-6 object-contain bg-[#efecf7] rounded shrink-0"
      />
      <span className="text-gray-900 font-semibold">{name}</span>
    </Link>
  );
};

export default ProductMenuDropDown;
