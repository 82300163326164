import Cookie from "universal-cookie";

const cookie = new Cookie();
const domain = process.env.GATSBY_ACP_DOMAIN || "";

export default {
  get: (key: string) => {
    return cookie.get(key);
  },
  set: (key: string, value: string, options?: { [key: string]: any }) => {
    return cookie.set(key, value, { ...options, domain });
  },
  remove: (key: string) => {
    return cookie.remove(key, { path: "/", domain });
  },
};
