exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-product-machine-translation-tsx": () => import("./../../../src/pages/product/machine-translation.tsx" /* webpackChunkName: "component---src-pages-product-machine-translation-tsx" */),
  "component---src-pages-product-ocr-general-document-tsx": () => import("./../../../src/pages/product/ocr-general-document.tsx" /* webpackChunkName: "component---src-pages-product-ocr-general-document-tsx" */),
  "component---src-pages-product-ocr-license-plate-tsx": () => import("./../../../src/pages/product/ocr-license-plate.tsx" /* webpackChunkName: "component---src-pages-product-ocr-license-plate-tsx" */),
  "component---src-pages-product-ocr-passport-tsx": () => import("./../../../src/pages/product/ocr-passport.tsx" /* webpackChunkName: "component---src-pages-product-ocr-passport-tsx" */),
  "component---src-pages-product-ocr-receipt-tsx": () => import("./../../../src/pages/product/ocr-receipt.tsx" /* webpackChunkName: "component---src-pages-product-ocr-receipt-tsx" */),
  "component---src-pages-product-ocr-thai-id-card-tsx": () => import("./../../../src/pages/product/ocr-thai-id-card.tsx" /* webpackChunkName: "component---src-pages-product-ocr-thai-id-card-tsx" */),
  "component---src-pages-product-sentiment-analysis-tsx": () => import("./../../../src/pages/product/sentiment-analysis.tsx" /* webpackChunkName: "component---src-pages-product-sentiment-analysis-tsx" */),
  "component---src-pages-product-speech-segmentation-tsx": () => import("./../../../src/pages/product/speech-segmentation.tsx" /* webpackChunkName: "component---src-pages-product-speech-segmentation-tsx" */),
  "component---src-pages-product-speech-to-text-tsx": () => import("./../../../src/pages/product/speech-to-text.tsx" /* webpackChunkName: "component---src-pages-product-speech-to-text-tsx" */),
  "component---src-pages-product-text-classification-tsx": () => import("./../../../src/pages/product/text-classification.tsx" /* webpackChunkName: "component---src-pages-product-text-classification-tsx" */),
  "component---src-pages-product-time-series-forecasting-tsx": () => import("./../../../src/pages/product/time-series-forecasting.tsx" /* webpackChunkName: "component---src-pages-product-time-series-forecasting-tsx" */),
  "component---src-pages-product-word-tokenization-tsx": () => import("./../../../src/pages/product/word-tokenization.tsx" /* webpackChunkName: "component---src-pages-product-word-tokenization-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

