import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

import smoothscroll from "smoothscroll-polyfill";

import "./src/global.css";

import Layout from "./src/components/Layout";

declare global {
  interface Window {
    locations: any;
    previousPath: any;
  }
}

if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

// export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
//   window.locations = window.locations || [document.referrer];
//   window.locations.push(window.location.href);
//   window.previousPath = window.locations[window.locations.length - 2];
// };

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  props,
  element,
}) => {
  return React.cloneElement(
    element, // I18nextProvider
    props,
    element.props.children &&
      React.cloneElement(
        element.props.children, // I18nextContext.Provider
        element.props.children?.props,
        <Layout props={props}>{element}</Layout>
      )
  );
};
