import cookieService from "./cookieService";

type CookieType = {
  category: string;
  accept: boolean;
};

type CategoryType = {
  key: string;
  name: string;
  services: ServiceType[];
};

type ServiceType = {
  name: string;
  domain: string;
};

export const CONSTANT_COOKIES = ["necessary_cookies"];

export const DEFAULT_COOKIES: CookieType[] = [
  {
    category: "necessary_cookies",
    accept: true,
  },
  {
    category: "performance_cookies",
    accept: false,
  },
  {
    category: "functionality_cookies",
    accept: false,
  },
  {
    category: "targeting_cookies",
    accept: false,
  },
];

export const COOKIES_CATEGORY: CategoryType[] = [
  {
    key: "necessary_cookies",
    name: "Necessary Cookies",
    services: [
      {
        name: "_ga",
        domain: "Google Analytics",
      },
      {
        name: "_gid",
        domain: "Google Analytics",
      },
    ],
  },
  {
    key: "performance_cookies",
    name: "Performance Cookies",
    services: [],
  },
  {
    key: "functionality_cookies",
    name: "Functionality Cookies",
    services: [],
  },
  {
    key: "targeting_cookies",
    name: "Targeting Cookies",
    services: [],
  },
];

export const setAcceptCookieByCategories = (acceptCategory: string[]) => {
  const nextYearDate = new Date();
  nextYearDate.setFullYear(new Date().getFullYear() + 1);

  const newCookieSettings: CookieType[] = DEFAULT_COOKIES.map(
    (cookie: CookieType) => {
      if (acceptCategory.includes(cookie.category)) {
        cookie.accept = true;
      }
      return cookie;
    }
  );
  cookieService.set("cookie_settings", JSON.stringify(newCookieSettings), {
    expires: nextYearDate,
  });
};

export const acceptAllCookies = () => {
  const nextYearDate = new Date();
  nextYearDate.setFullYear(new Date().getFullYear() + 1);
  const newCookieSettings: CookieType[] = DEFAULT_COOKIES.map(
    (cookie: CookieType) => {
      cookie.accept = true;
      return cookie;
    }
  );
  cookieService.set("cookie_settings", JSON.stringify(newCookieSettings), {
    expires: nextYearDate,
  });
};

export const getCookieSettings = () => {
  const cookieSettings = cookieService.get("cookie_settings");
  return cookieSettings ? cookieSettings : DEFAULT_COOKIES;
};

export const alreadySettingCookies = cookieService.get("cookie_settings")
  ? true
  : false;
