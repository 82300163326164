import React, { memo, useEffect, useState } from "react";
import loadable from "@loadable/component";
import { PageProps } from "gatsby";
import clsx from "clsx";

import { Link, useI18next } from "gatsby-plugin-react-i18next";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const CookieSettingsModal = loadable(() => import("./CookieSettingsModal"), {
  ssr: false,
});

import { alreadySettingCookies, acceptAllCookies } from "../utils/cookies";

type LayoutProps = {
  props: PageProps;
  children: any;
};

const Layout = ({ props, children }: LayoutProps) => {
  const [showCookie, setShowCookie] = useState(false);

  const { language } = useI18next();
  useEffect(() => {
    setShowCookie(!alreadySettingCookies);
  }, [alreadySettingCookies]);

  return (
    <div
      className={clsx(
        "min-h-[100dvh] flex flex-col justify-between overflow-hidden relative",
        language === "th" ? "font-sukhumvit" : "font-inter"
      )}
    >
      {showCookie && <CookieSettingsCard setShowCookie={setShowCookie} />}
      <Navbar {...props} />
      <main className="relative flex flex-col grow">{children}</main>
      <Footer />
    </div>
  );
};

type CookieSettingsCardType = {
  setShowCookie: React.Dispatch<React.SetStateAction<boolean>>;
};

const CookieSettingsCard = ({ setShowCookie }: CookieSettingsCardType) => {
  const [showCookieSettingsModal, setShowCookieSettingsModal] = useState(false);

  return (
    <>
      {showCookieSettingsModal && (
        <CookieSettingsModal
          setShowModal={setShowCookieSettingsModal}
          setShowCookie={setShowCookie}
        />
      )}
      <div className="fixed bottom-0 flex flex-col gap-4 right-0 m-4 z-[1000] max-w-[400px] md:p-6 p-4 bg-gray-50 shadow-xl rounded-2xl">
        <div>
          <div className="flex justify-between">
            <div className="text-lg font-semibold text-gray-900">
              We use cookies
            </div>
          </div>
          <div className="mt-1 text-sm text-gray-600">
            We use cookies to ensure that we give your the best experience on
            our website and to gather information about the way users interact
            with our website.
            <Link
              to={`/cookie-policy`}
              placeholder="Cookies Policy"
              className="ml-1 underline"
            >
              Cookies Policy
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <button
            type="button"
            aria-label="accept all"
            onClick={() => {
              acceptAllCookies();
              setShowCookie(false);
            }}
            className="py-2.5 px-4 basis-0 grow text-sm font-semibold rounded-lg border border-primary-600 bg-primary-600 shadow-xs hover:bg-primary-700 disabled:border-primary-200 disabled:bg-primary-200 text-white focus:ring-4 focus:ring-primary-100"
          >
            Accept
          </button>
          <button
            type="button"
            aria-label="show cookie settings"
            onClick={() => {
              setShowCookieSettingsModal(true);
            }}
            className="py-2.5 px-4 basis-0 grow text-sm font-semibold rounded-lg border border-gray-300 bg-white shadow-xs hover:bg-gray-50 disabled:border-gray-200 text-gray-700 disabled:text-gray-300 focus:ring-4 focus:ring-gray-100"
          >
            Set your cookie preferences
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(Layout);
